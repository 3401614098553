html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #ffff;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 3;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}
@media only screen and (max-width: 1366px) {
    .container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media (min-width: 1300px) {
    .container {
        min-width: 1350px;
    }
}
h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 65px;
    line-height: 70px;
    margin-bottom: 60px;
    color: white;
    margin-top: 70px;
    position: relative;
    z-index: 4;
}
.h1-span {
    color: #fa437a;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 62px;
    margin-bottom: 25px;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    h2 {
        font-size: 28px;
        line-height: 36px;
    }
}

p {
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 300;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

.site-logo {
    font-weight: bold;
    font-size: 22px;
    color: transparent;
    background-image: url('../image/logo.webp');
    background-repeat: no-repeat;
    background-size: 155px;
}

.logo-wrapper {
    padding-top: 40px;
    padding-bottom: 22px;
}
.site-header {
    background-color: #1c232c;
    padding-bottom: 55px;
}
.site-header-inner {
    width: 60%;
    position: relative;
}
.site-header-inner::before {
    position: absolute;
    content: '';
    width: 1266px;
    height: 1266px;
    background-color: #fa437a;
    opacity: 0.06;
    filter: blur(200px);
    border-radius: 50%;
    top: -250px;
    left: 400px;
}
.site-header-inner::after {
    position: absolute;
    content: '';
    background-image: url('../image/hero.webp');
    background-repeat: no-repeat;
    width: 722px;
    height: 733px;
    top: -16px;
    left: 627px;
    background-size: 672px;
}
.body-wrapper {
    overflow-x: hidden;
}
@media only screen and (max-width: 768px) {
    .site-header-inner {
        padding: 26px 20px 28px;
    }
}

.header-image {
    position: absolute;
    right: 0;
    bottom: 0;
}
@media only screen and (max-width: 768px) {
    .header-image {
        display: none;
    }
}

.header-description {
    margin-bottom: 30px;
    font-weight: 100;
    position: relative;
    z-index: 3;
    width: 82%;
    line-height: 32px;
    font-size: 18px;
}
@media only screen and (max-width: 768px) {
    .header-description {
        margin-bottom: 26px;
    }
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}
@media only screen and (max-width: 768px) {
    .nav-wrapper p {
        margin-bottom: 20px;
    }
}

.nav-wrapper ul {
    display: flex;
}
.nav-wrapper li {
    box-shadow: 0px 4px 33px 6px rgba(0, 0, 0, 0.1);
    width: 227px;
    height: 65px;
    border-radius: 50px;
    margin-right: 31px;
}
@media only screen and (max-width: 768px) {
    .nav-wrapper ul {
        display: flex;
    }
    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
        margin-right: 25px;
    }
    .nav-wrapper li {
        width: 100%;
    }
}

.nav-wrapper a {
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
    display: block;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    text-indent: 67px;
    line-height: 65px;
    border-radius: 50px;
    margin-bottom: 58px;
    position: relative;
}
.start-here {
    color: #fa437a;
    border: 1px solid #fa437a;
}
.learn-how {
    color: #ffff;
    border: 1px solid #ffff;
}

.nav-wrapper a.start-here:hover {
    opacity: 1;
    background-color: #fa437a;
    color: #1c232c;
    transition: all 0.2s linear;
    cursor: pointer;
    box-shadow: inset 0 0 0 50px #fa437a;
}
.nav-wrapper a.learn-how:hover {
    opacity: 1;
    background-color: #ffff;
    color: #1c232c;
    transition: all 0.2s linear;
    cursor: pointer;
    box-shadow: inset 0 0 0 50px #ffff;
}

.nav-wrapper a:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    top: 16px;
    left: 20px;
}

/* MAIN  */
.main {
    background-color: #161c25;
    position: relative;
    z-index: 100;
    padding-bottom: 5px;
}
.main p {
    font-size: 19px;
    line-height: 40px;
}
.what-is,
.how-do-they-work {
    text-align: center;
}
h2 {
    padding-top: 77px;
}
.light {
    font-weight: 300;
}
.medium {
    font-weight: 500;
}
.p-links {
    color: #fa437a;
    text-decoration: underline;
}
.examples {
    padding: 45px 60px;
    border: 1px solid #3b495f;
    border-radius: 20px;
    text-align: left;
    margin-top: 55px;
    margin-bottom: 65px;
}

h3 {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 22px;
}
.how-do-they-work {
    position: relative;
}
.how-do-they-work::before {
    content: '';
    position: absolute;
    width: 937px;
    height: 937px;
    left: 140px;
    top: 140px;
    background: #2fb3ec;
    opacity: 0.09;
    filter: blur(200px);
    z-index: -1;
}
.images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;
}
.img1 {
    background: url(../image/watch-skins.webp);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 280px;
}
.img2 {
    background: url(../image/luxury-watch-gang.webp);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 280px;
    margin-left: 20px;
}
.img3 {
    background: url(../image/wisekey.webp);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 280px;
}
.img4 {
    background: url(../image/4k-home-page.webp);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 280px;
    margin-left: 20px;
}
#top-nft-watches {
    text-align: center;
}

/* TABLE  */
table {
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #344254;
}
thead th {
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 20px;
}
thead {
    margin-bottom: 22px;
    display: block;
    padding: 25px 0;
    border-top: 1px solid #344254;
    border-bottom: 1px solid #344254;
    font-size: 20px;
}
.t-link a {
    width: 314px;
    height: 50px;
    border: 1px solid #fa437a;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    color: #fa437a;
    align-items: center;
}
.t-link:hover a {
    background-color: #fa437a;
}
.t-link:hover a {
    color: #161c25;
}
td:first-of-type {
    text-align: left;
}

.t-link a {
    color: #fa437a;
}
tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
tbody tr {
    margin-bottom: 20px;
}
.th-name {
    font-size: 23px;
    font-weight: 700;
}
.th-how {
    font-weight: 300;
    text-align: center;
}
th,
td {
    width: 300px;
}
th:first-of-type {
    text-align: left;
}

/* ENDS TABLE  */

.which-one {
    text-align: center;
}
.which-one p:not(:last-child) {
    margin-bottom: 25px;
}
#nft-watches-marketplace {
    position: relative;
}
#nft-watches-marketplace::before {
    content: '';
    position: absolute;
    width: 937px;
    height: 937px;
    left: 140px;
    top: 250px;
    background: #2fb3ec;
    opacity: 0.09;
    filter: blur(200px);
    z-index: -1;
}
.rent-watch {
    padding-left: 450px;
    background: url(../image/nft-watch-1.webp);
    background-repeat: no-repeat;
    background-position: 25px;
}

/* FAQ SECTION  */
.text-center h2 {
    color: white;
    font-weight: 300;
}

.faq-wrapper {
    position: relative;
    z-index: 2;
}
.faq-section-wrapper {
    padding-bottom: 90px;
}
/* .faq-heading {
  margin-bottom: 38px!important;
  padding-top: 68px!important
} */

.faq.expanded + .faq {
    border-top: 0;
}

.faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.faq-title h3 {
    color: white;
    font-size: 28px;
    font-weight: 400;
    height: 98px;
    line-height: 98px;
    pointer-events: none;
    margin-bottom: 0 !important;
}
.faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 42px;
    height: 49px;
    justify-content: center;
    margin-right: 42px;
    width: 49px;
}

.faq-btn:hover,
.faq-title:hover {
    cursor: pointer;
}

.faq {
    padding-left: 40px;
    transition: background-color 0.2s linear, font-weight 0.2s linear, border-radius 0.2s linear;
    position: relative;
    margin-bottom: 25px;
}
.faq-background-outer {
    border: 1px solid #3b495f;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.faq-content {
    position: relative;
    z-index: 2;
}

.faq .faq-body {
    color: #3b495f;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 44px;
    border-top: 1px solid #3b495f;
    padding-top: 25px;
    width: 95%;
}
.faq.collapsed .faq-body {
    display: none;
}
.faq-body a {
    font-weight: bold;
    color: white;
    text-decoration: underline;
}

.faq-btn span {
    pointer-events: none;
}

.faq.collapsed .faq-btn span {
    color: white;
}

.faq.expanded .faq-btn span {
    color: white;
}

.faq.collapsed .faq-btn span.plus,
.faq.expanded .faq-btn span.minus {
    display: block;
}

.faq.collapsed .faq-btn span.minus,
.faq.expanded .faq-btn span.plus {
    display: none;
}
.faq.expanded .faq-title h3 {
    font-weight: 700;
}
.text-center {
    text-align: center;
    color: white;
}
.text-center p {
    padding-bottom: 50px;
    color: #fff;
}
.faq-title h3 {
    font-size: 21px;
    font-weight: 300;
    line-height: 20px;
    display: flex;
    align-items: center;
}
.faq-body p {
    color: white;
    text-align: left;
}
/* ENDS FAQ  */

.about-us {
    text-align: center;
    padding: 0px 60px 40px;
    background-color: #0f141b;
    border-radius: 20px;
    padding-left: 525px;
    background-image: url(../image/nft-watch-2.webp);
    background-repeat: no-repeat;
    background-position: 70px;
}
.about-us a {
    width: 210px;
    height: 50px;
    border: 1px solid #fa437a;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    color: #fa437a;
    align-items: center;
    margin: 25px auto;
    transition: all 0.2s ease;
}
a.contact-btn:hover {
    background: #ff417a;
    color: #ffff;
}
.contact-btn {
    position: relative;
    cursor: pointer;
}

footer {
    background-color: #161c25;
}
footer .container {
    display: flex;
    justify-content: center;
    align-items: center;
    columns: #707278;
    padding: 25px 20px;
}

@media only screen and (max-width: 768px) {
    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
    }
}

footer .copyright {
    color: #828c98;
    font-size: 14px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 450px) {
    .img1,
    .img2,
    .img3,
    .img4 {
        height: 220px;
    }
}
@media only screen and (max-width: 385px) {
    .img1,
    .img2,
    .img3,
    .img4 {
        height: 177px;
    }
}
@media only screen and (max-width: 440px) {
    .rent-watch {
        background-position: center 141px !important;
        background-size: 265px !important;
    }
}

.mobile-table {
    display: none;
}

/* MOBILE VERSION  */

@media only screen and (max-width: 768px) {
    header,
    article,
    footer {
        padding: 0px 22px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    .site-header-inner {
        width: 100%;
        text-align: center;
    }
    .header-description {
        width: 100%;
    }

    .nav-wrapper a {
        text-indent: 0;
        line-height: 50px;
    }
    .nav-wrapper li {
        margin-right: 0;
    }
    .logo-wrapper {
        display: flex;
        justify-content: center;
    }
    .site-header-inner h1 {
        margin-top: 0;
    }
    .site-header-inner::before {
        top: -340px;
        left: 75px;
    }
    .site-header {
        padding-bottom: 0;
    }
    h2 {
        padding-top: 45px;
    }
    p {
        font-size: 17px !important;
    }
    .examples {
        text-align: center;
        padding: 45px 25px;
    }
    .images {
        grid-template-columns: 1fr;
    }
    .img2,
    .img4 {
        margin-left: 0;
    }
    table {
        display: none;
    }
    .mobile-table {
        display: block;
    }
    .table-name,
    .table-how-to {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .th-how {
        font-weight: 500;
    }
    .table-website {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .table-website span {
        margin-bottom: 25px;
        margin-top: 25px;
    }
    .table-content {
        padding: 25px 0;
        border-top: 1px solid #3b495f;
    }
    .table-content:last-child {
        border-bottom: 1px solid #3b495f;
    }
    .table-content span {
        font-weight: 100;
        text-transform: uppercase;
    }
    .rent-watch {
        background-position: center 106px;
        background-size: 300px;
    }
    .rent-watch p {
        margin-top: 236px;
    }
    .about-us {
        background-position: center 150px;
        background-size: 250px;
    }
    .about-us p:first-of-type {
        margin-top: 330px;
    }
    #nft-watches-marketplace::before {
        left: -114px;
    }

    /* Read more  */
    .m-slide-down {
        height: 0;
        overflow: hidden;
        -moz-transition: height 0.5s;
        -ms-transition: height 0.5s;
        -o-transition: height 0.5s;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
    }
    .m-hidden,
    .mobile-detector {
        display: none;
    }
    .m-visible {
        display: initial;
    }
    .m-hidden {
        display: initial;
    }
    .m-slide-down-measure p {
        margin: 0 !important;
        padding-top: 20px;
    }
    .m-readmore-btn {
        background: transparent;
        border: none;
        text-transform: uppercase;
        font-size: 0;
        font-weight: 500;
        color: white;
        width: 100%;
        margin: 15px auto 50px;
    }

    .m-readmore-btn::before {
        cursor: pointer;
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: top;
        margin-left: 5px;
        color: rgb(240, 240, 240);
        font-size: 24px !important;
    }

    .m-readmore-btn.read-more-2::before {
        content: '\002B';
    }
    .m-readmore-btn.collapse-2::before {
        content: '\2212';
    }

    .m-readmore-btn * {
        pointer-events: none;
    }
    .m-visible {
        display: initial;
    }

    .m-hidden {
        display: none;
    }
    .m-readmore-btn.read-more-3::before {
        content: 'See more';
        cursor: pointer;
        text-transform: none !important;
        display: flex;
        justify-content: center;
        color: white;
        font-size: 24px !important;
        width: 100%;
        border-top: 1px solid #344254;
        border-bottom: 1px solid #344254;
        padding-top: 25px;
        padding-bottom: 25px;
        align-items: center;
        font-weight: 300;
        font-size: 16px;
    }
    footer .logo-wrapper {
        padding-bottom: 8px;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .header-description {
        width: 100%;
    }
}
@media only screen and (max-width: 768px) and (min-width: 569px) {
    .img1,
    .img3 {
        margin-bottom: 10px;
    }
}

@mixin transition($params) {
    -webkit-transition: $params;
    -moz-transition: $params;
    -khtml-transition: $params;
    -o-transition: $params;
    transition: $params;
}

@mixin rotate($params) {
    -webkit-transform: rotate($params);
    -moz-transform: rotate($params);
    -khtml-transform: rotate($params);
    -o-transform: rotate($params);
    transform: rotate($params);
}
